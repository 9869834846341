var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('b-modal', {
    ref: "modal-clock-time",
    staticStyle: {
      "width": "80vw"
    },
    attrs: {
      "size": "lg",
      "centered": "",
      "hide-footer": "",
      "title": "Add time"
    }
  }, [_c('b-container', [_c('b-overlay', {
    attrs: {
      "show": _vm.isLoading || _vm.sickDetails.isLoading || _vm.vacationDetails.isLoading || _vm.rangeDetails.isLoading,
      "opacity": 0.5,
      "spinner-variant": "secondary",
      "rounded": "sm"
    }
  }, [_c('b-row', {
    staticClass: "mb-2"
  }, [_c('b-col', {
    staticClass: "font-weight-bold",
    attrs: {
      "cols": "4",
      "lg": "2"
    }
  }, [_vm._v(" Now: ")]), _c('b-col', {
    attrs: {
      "cols": "8",
      "lg": "4"
    }
  }, [_vm._v(" " + _vm._s(_vm.date) + " " + _vm._s(_vm.time) + " ")]), _c('b-col', {
    staticClass: "font-weight-bold",
    attrs: {
      "cols": "4",
      "lg": "2"
    }
  }, [_vm._v(" Name: ")]), _c('b-col', {
    attrs: {
      "cols": "8",
      "lg": "4"
    }
  }, [_vm._v(" " + _vm._s(this.userData.full_name) + " ")])], 1), _c('b-row', [_c('b-col', {
    staticClass: "font-weight-bold mb-2",
    attrs: {
      "cols": "4",
      "lg": "2"
    }
  }, [_vm._v(" Day type: ")]), _c('b-col', {
    staticClass: "mb-2",
    attrs: {
      "cols": "8",
      "lg": "4"
    }
  }, [_c('inline-select', {
    attrs: {
      "id": "day-type",
      "value": _vm.data.daytype,
      "label": "Day type",
      "hide-label": true,
      "readonly": false,
      "options": _vm.controls.daytype.options,
      "allow-empty": false,
      "mode": _vm.$constants.FORMCONTROLMODE.EDIT,
      "required": true
    },
    on: {
      "changed": _vm.onDayTypeChange
    }
  })], 1), _c('b-col', {
    staticClass: "font-weight-bold mb-2",
    attrs: {
      "cols": "4",
      "lg": "2"
    }
  }, [_vm._v(" Daily duration: ")]), _c('b-col', {
    staticClass: "mb-2",
    attrs: {
      "cols": "8",
      "lg": "4"
    }
  }, [_c('inline-select', {
    attrs: {
      "id": "duration",
      "value": _vm.data.duration,
      "label": "Duration",
      "hide-label": true,
      "readonly": false,
      "options": _vm.controls.duration.options,
      "allow-empty": false,
      "mode": _vm.$constants.FORMCONTROLMODE.EDIT,
      "required": true
    },
    on: {
      "changed": _vm.onDurationChange
    }
  })], 1)], 1), _c('b-row', [_c('b-col', {
    staticClass: "font-weight-bold mb-2",
    attrs: {
      "cols": "4",
      "lg": "3"
    }
  }, [_vm._v(" Ignore weekends ")]), _c('b-col', {
    staticClass: "mb-2",
    attrs: {
      "cols": "2",
      "lg": "3"
    }
  }, [_c('inline-switch', {
    attrs: {
      "id": "sw-ignore-weekends",
      "value": _vm.data.ignoreWeekends,
      "hide-label": true
    },
    on: {
      "changed": _vm.onIgnoreWeekendsChange
    }
  })], 1), _c('b-col', {
    staticClass: "font-weight-bold mb-2",
    attrs: {
      "cols": "4",
      "lg": "3"
    }
  }, [_vm._v(" Request unpaid time ")]), _c('b-col', {
    staticClass: "mb-2",
    attrs: {
      "cols": "2",
      "lg": "3"
    }
  }, [_c('inline-switch', {
    attrs: {
      "id": "sw-unpaid-time",
      "value": _vm.data.unpaidTime,
      "hide-label": true
    },
    on: {
      "changed": _vm.onUnpaidTimeChange
    }
  })], 1)], 1), _vm.isSingleDay ? _c('span', [_c('b-row', [_c('b-col', {
    staticClass: "font-weight-bold mb-2",
    attrs: {
      "cols": "4",
      "lg": "2"
    }
  }, [_vm._v("Date:")]), _c('b-col', {
    staticClass: "mb-2",
    attrs: {
      "cols": "8",
      "lg": "4"
    }
  }, [_c('date-range-picker-custom', {
    attrs: {
      "id": "date-picker",
      "default-range": "Today",
      "single-date-picker": true,
      "ranges": {}
    },
    model: {
      value: _vm.data.date1,
      callback: function callback($$v) {
        _vm.$set(_vm.data, "date1", $$v);
      },
      expression: "data.date1"
    }
  })], 1)], 1)], 1) : _vm._e(), !_vm.isSingleDay ? _c('span', [_c('b-row', [_c('b-col', {
    staticClass: "font-weight-bold mb-2",
    attrs: {
      "cols": "4",
      "lg": "2"
    }
  }, [_vm._v("From date:")]), _c('b-col', {
    staticClass: "mb-2",
    attrs: {
      "cols": "8",
      "lg": "4"
    }
  }, [_c('date-range-picker-custom', {
    attrs: {
      "id": "date-picker",
      "default-range": "Today",
      "single-date-picker": true,
      "ranges": {}
    },
    model: {
      value: _vm.data.date1,
      callback: function callback($$v) {
        _vm.$set(_vm.data, "date1", $$v);
      },
      expression: "data.date1"
    }
  })], 1), _c('b-col', {
    staticClass: "font-weight-bold mb-2",
    attrs: {
      "cols": "4",
      "lg": "2"
    }
  }, [_vm._v("To date:")]), _c('b-col', {
    staticClass: "mb-2",
    attrs: {
      "cols": "8",
      "lg": "4"
    }
  }, [_c('date-range-picker-custom', {
    attrs: {
      "id": "date-picker",
      "default-range": "Today",
      "single-date-picker": true,
      "ranges": {}
    },
    model: {
      value: _vm.data.date2,
      callback: function callback($$v) {
        _vm.$set(_vm.data, "date2", $$v);
      },
      expression: "data.date2"
    }
  })], 1)], 1)], 1) : _vm._e(), _vm.data.daytype.id !== _vm.$constants.DAY_TYPE.VACATION && _vm.data.daytype.id !== _vm.$constants.DAY_TYPE.PERSONAL_LEAVE ? _c('b-row', [_c('b-col', {
    staticClass: "font-weight-bold mb-2",
    attrs: {
      "cols": "4",
      "lg": "2"
    }
  }, [_vm._v(" From time: ")]), _c('b-col', {
    staticClass: "mb-2",
    attrs: {
      "cols": "8",
      "lg": "4"
    }
  }, [_c('vue-timepicker', {
    attrs: {
      "minute-interval": 5,
      "format": "HH:mm",
      "lazy": ""
    },
    model: {
      value: _vm.data.time1,
      callback: function callback($$v) {
        _vm.$set(_vm.data, "time1", $$v);
      },
      expression: "data.time1"
    }
  })], 1), _c('b-col', {
    staticClass: "font-weight-bold mb-2",
    attrs: {
      "cols": "4",
      "lg": "2"
    }
  }, [_vm._v(" To time: ")]), _c('b-col', {
    staticClass: "mb-2",
    attrs: {
      "cols": "8",
      "lg": "4"
    }
  }, [_c('vue-timepicker', {
    attrs: {
      "minute-interval": 5,
      "format": "HH:mm",
      "lazy": ""
    },
    model: {
      value: _vm.data.time2,
      callback: function callback($$v) {
        _vm.$set(_vm.data, "time2", $$v);
      },
      expression: "data.time2"
    }
  })], 1)], 1) : _vm._e(), _c('span', [_c('b-row', [_c('b-col', {
    staticClass: "font-weight-bold mb-2",
    attrs: {
      "cols": "12",
      "lg": "12"
    }
  }, [_vm._v("Available dates:")]), _c('b-col', {
    staticClass: "mb-2",
    attrs: {
      "cols": "12",
      "lg": "12"
    }
  }, [_vm._v(_vm._s(_vm.data.availableDates) + " ")])], 1), _c('b-row', [_c('b-col', {
    staticClass: "font-weight-bold mb-2",
    attrs: {
      "cols": "12",
      "lg": "12"
    }
  }, [_vm._v("Not available dates:")]), _c('b-col', {
    staticClass: "mb-2",
    attrs: {
      "cols": "12",
      "lg": "12"
    }
  }, [_vm._v(" " + _vm._s(_vm.data.notAvailableDates) + " ")])], 1), _c('b-row', [_c('b-col', {
    staticClass: "font-weight-bold mb-2",
    attrs: {
      "cols": "12",
      "lg": "12"
    }
  }, [_vm._v(" Range details (days): "), _vm.rangeDetails.isLoading ? _c('b-spinner', {
    attrs: {
      "small": "",
      "type": "grow"
    }
  }) : _vm._e()], 1), _c('b-col', {
    staticClass: "mb-2",
    attrs: {
      "cols": "12",
      "lg": "12"
    }
  }, [_c('b-table', {
    attrs: {
      "items": _vm.rangeDetails.data,
      "responsive": ""
    }
  })], 1)], 1)], 1), _vm.data.daytype.id === _vm.$constants.DAY_TYPE.VACATION || _vm.data.daytype.id === _vm.$constants.DAY_TYPE.PERSONAL_LEAVE ? _c('b-row', [_c('b-col', {
    staticClass: "font-weight-bold mb-2",
    attrs: {
      "cols": "12",
      "lg": "12"
    }
  }, [_vm._v(" Vacation Details (hours): "), _vm.vacationDetails.isLoading ? _c('b-spinner', {
    attrs: {
      "small": "",
      "type": "grow"
    }
  }) : _vm._e()], 1), _c('b-col', {
    staticClass: "mb-2",
    attrs: {
      "cols": "12",
      "lg": "12"
    }
  }, [_c('b-table', {
    attrs: {
      "items": _vm.vacationDetails.data,
      "responsive": ""
    }
  })], 1)], 1) : _vm._e(), _vm.data.daytype.id === _vm.$constants.DAY_TYPE.SICK ? _c('b-row', [_c('b-col', {
    staticClass: "font-weight-bold mb-2",
    attrs: {
      "cols": "12",
      "lg": "12"
    }
  }, [_vm._v(" Sick Details (hours): "), _vm.sickDetails.isLoading ? _c('b-spinner', {
    attrs: {
      "small": "",
      "type": "grow"
    }
  }) : _vm._e()], 1), _c('b-col', {
    staticClass: "mb-2",
    attrs: {
      "cols": "12",
      "lg": "12"
    }
  }, [_c('b-table', {
    attrs: {
      "items": _vm.sickDetails.data,
      "responsive": ""
    }
  })], 1)], 1) : _vm._e(), _vm.location === 'offsite' ? _c('b-row', [_c('b-col', {
    staticClass: "font-weight-bold mb-2",
    attrs: {
      "cols": "12",
      "lg": "12"
    }
  }, [_vm._v(" Notes: ")]), _c('b-col', {
    staticClass: "mb-2",
    attrs: {
      "cols": "12",
      "lg": "12"
    }
  }, [_c('inline-input', {
    attrs: {
      "value": _vm.data.notes,
      "rows": 2,
      "label": "Notes",
      "hide-label": true,
      "readonly": false,
      "mode": _vm.$constants.FORMCONTROLMODE.EDIT,
      "required": _vm.controls.notes.required,
      "is-text-area": true
    },
    on: {
      "changed": _vm.onNotesChanged
    }
  })], 1)], 1) : _vm._e(), _vm.location === 'offsite' ? _c('b-row', [_c('b-col', {
    attrs: {
      "cols": "12",
      "lg": "12"
    }
  }, [_vm._v(" Rules: "), _c('pre', [_vm._v(_vm._s(_vm.ruleNotesSelected))])])], 1) : _vm._e(), _vm.location === 'offsite' ? _c('b-row', [_c('b-col', {
    attrs: {
      "cols": "12",
      "lg": "12"
    }
  }, [_vm._v(" Checks: "), _c('pre', {
    domProps: {
      "innerHTML": _vm._s(_vm.conclusionText)
    }
  })])], 1) : _vm._e()], 1)], 1), _c('hr'), _c('div', {
    staticClass: "form-row d-flex justify-content-end"
  }, [_vm.controls.saveButton.visible ? _c('b-button', {
    staticClass: "m-1",
    attrs: {
      "variant": _vm.controls.saveButton.color,
      "disabled": _vm.controls.saveButton.disabled || _vm.isLoading
    },
    on: {
      "click": function click($event) {
        return _vm.save();
      }
    }
  }, [!_vm.isLoading ? _c('font-awesome-icon', {
    attrs: {
      "icon": _vm.controls.saveButton.icon
    }
  }) : _vm._e(), _vm.isLoading ? _c('b-spinner', {
    attrs: {
      "small": "",
      "type": "grow"
    }
  }) : _vm._e(), _vm._v(" " + _vm._s(_vm.controls.saveButton.title) + " ")], 1) : _vm._e(), _c('b-button', {
    staticClass: "m-1",
    attrs: {
      "variant": "outline-dark"
    },
    on: {
      "click": function click($event) {
        return _vm.close();
      }
    }
  }, [_c('font-awesome-icon', {
    attrs: {
      "icon": "ban"
    }
  }), _vm._v(" Close ")], 1)], 1)], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }